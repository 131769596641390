$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

.tileWrapper {
    min-width: 0;
}

.favoritesWrapper {
    @include sassy-z-index(bump);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    padding: $sassy-spacing-x-small;
}

.heartIconWrapper {
    transform: scale(0.8);
    &.showLightBox {
        margin-top: 0;
    }
}
