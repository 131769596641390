$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$closeIconSize: 12px;
$colorDotSize: 12px;

.filterItemWrapper {
    margin-right: $sassy-spacing-x-small;
    margin-bottom: $sassy-spacing-x-small;
}

.container {
    @include sassy-font-body($size: small, $type: none);
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-bottom: $sassy-spacing-x-small;
}

.value {
    @include sassy-font-body($size: none, $type: heavy);
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.closeIconWrapper {
    width: $closeIconSize;
    height: $closeIconSize;
    flex: 0 0 $closeIconSize;
    margin-left: $sassy-spacing-x-small;
    cursor: pointer;
    color: $sassy-color-iron;
}

.truncateLabel {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    white-space: pre;
    flex: 1;
}

.colorDot {
    height: $colorDotSize;
    width: $colorDotSize;
    border-radius: 50%;
    display: inline-block;
}
