$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';
$topRefineMenuDrawerSpacing: 12px;
$topRefineMenuTitleSpacing: 14px;
$focusOutlinePadding: 2px;

.container {
    padding: $focusOutlinePadding; //allow space for focus outlines
    position: relative; // position relative keeps input caret at the place when scrolling, iOS - safari/chrome bug fix.
    border-bottom: 1px solid $sassy-color-dolphin;

    @if $device-type == 'mobile' {
        padding-right: $sassy-spacing-small;
    }
}

.facetContent {
    &[data-state='collapsed'] {
        visibility: hidden;
    }
    &[data-state='expanded'] {
        overflow: visible;
    }

    @if $device-type == 'responsive' {
        background: inherit;
    } @else {
        padding: 0 0 0 $sassy-spacing-small;
        background: $sassy-color-moonstone;
    }

    @if $device-type == 'mobile' {
        background: inherit;
    }
}

.facetContainer {
    margin-left: 2px;
}
.titleWrapper {
    @if $device-type == 'responsive' {
        padding: $sassy-spacing-small
            0
            $sassy-spacing-x-small !important; //override dibs-element Link style
    } @else {
        @include sassy-font-body($size: small, $type: none);
        padding: $sassy-spacing-small !important; //override dibs-element Link style
        padding-right: 0 !important; //override dibs-element Link style
    }

    color: $sassy-color-noir;
    &:hover {
        color: $sassy-color-noir;
    }
}
.title {
    @include sassy-font-sub-header;
    flex-basis: 100%;
    color: $sassy-color-noir;

    &:hover {
        color: $sassy-color-noir;
    }
}

.button {
    display: block;
    padding-bottom: $sassy-spacing-small;
}

.abTestSelectedValue {
    @include sassy-font-sub-header($type: normal);
    color: $sassy-color-noir;
    text-transform: none;

    .dimension {
        padding-left: 3px;
    }
}
