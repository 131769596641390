$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

.wrapper {
    margin-bottom: $sassy-spacing-large;

    @include sassy-breakpoint-below(mobile) {
        @include primary-viewport-width;
        margin-top: $sassy-spacing-small;
        border-top: $sassy-spacing-x-small solid $sassy-color-moonstone;
        padding: $sassy-spacing-small;
    }
}

.header {
    @include sassy-font-header($size: medium);
    text-align: center;
    margin: $sassy-spacing-small 0 $sassy-spacing-large;
    @include sassy-breakpoint-below(mobile) {
        margin: 0 0 $sassy-spacing-small;
    }
}
