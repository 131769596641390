$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.popularSearches {
    @include sassy-font-body($size: medium);
    margin-top: $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: small);
        display: flex;
        justify-content: center;
        margin-top: $sassy-spacing-small;
    }
}

.popularSearchesCategory {
    @include sassy-font-body($size: medium, $type: heavy);

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: small, $type: heavy);
    }
}
