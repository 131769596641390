$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_animations';

$device-type: 'responsive';

.title {
    @if ($device-type == 'responsive') {
        @include sassy-font-header;
    } @else {
        @include sassy-font-header($size: medium);
    }
}

.copy {
    @if $device-type == 'responsive' {
        @include sassy-font-body($size: large);
    } @else {
        @include sassy-font-body;
    }
}

.searchTerm {
    @if $device-type == 'responsive' {
        @include sassy-font-body($size: large, $type: heavy);
    } @else {
        @include sassy-font-body($size: none, $type: heavy);
    }
}

.shimmer {
    @include sassy-animate-loading-shimmer;
}
