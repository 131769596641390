$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';

.container {
    margin: $sassy-spacing-small 0 $sassy-spacing-xx-large;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-bottom: 0;
    }
}
