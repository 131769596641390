$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_animations';

.tileContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: stretch;
    gap: $sassy-spacing-small;
    margin-top: $sassy-spacing-larger;
}

.tile {
    @include sassy-animate-overlay-hover;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $sassy-color-dolphin;
    padding: $sassy-spacing-small;
}

.image {
    max-height: 130px;
    max-width: 100%;
}

.title {
    @include sassy-font-header($size: small);
    text-align: center;
    margin: auto;
    padding-top: $sassy-spacing-x-small;
}
