$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

$menu-width: 260px;
$top-offset: 99px;

// http://simurai.com/blog/2011/07/26/webkit-scrollbar
@mixin scroll-bar {
    &::-webkit-scrollbar {
        display: initial;
        -webkit-appearance: none;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $sassy-color-dolphin;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    overflow-y: overlay;
}

.wrapper {
    width: $menu-width;
    margin-right: $sassy-spacing-x-large;
    flex-shrink: 0;
    padding-bottom: $sassy-spacing-larger;
    position: relative;
}

.topVisibilityOffset {
    top: -$top-offset;
    position: relative;
}

.menu {
    position: sticky;
    top: $top-offset;
    height: calc(100vh - 126px);
    width: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: $sassy-spacing-small;
    padding-bottom: $sassy-spacing-small;

    &::-webkit-scrollbar {
        display: none;
    }

    &:hover {
        @include scroll-bar;
    }
}

.scrollBar {
    @include scroll-bar;
}

.fullHeight {
    height: 100%;
    overflow: visible;
}
