$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

.container {
    margin-bottom: $sassy-spacing-large;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-bottom: $sassy-spacing-small;
    }
}

.topSpacer {
    border-top: 1px solid $sassy-color-moonstone;
    @include sassy-breakpoint-below(tablet-portrait) {
        border-top: $sassy-spacing-x-small solid $sassy-color-moonstone;
    }
}

.header {
    @include sassy-font-header($size: large);
    margin-top: $sassy-spacing-large;
    text-align: center;
    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-header($size: medium);
        margin-top: $sassy-spacing-small;
    }
}

.viewMoreSection {
    display: flex;
    justify-content: center;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-top: $sassy-spacing-x-small;
    }
}

.viewMoreButton {
    width: 200px;
    @include sassy-breakpoint-below(tablet-portrait) {
        width: 100%;
    }
}

.itemGrid {
    display: flex;
    flex-wrap: wrap;
    margin: $sassy-spacing-large 0 0;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin: 0;
    }
}

.respItemGrid {
    flex: 0 0 25%;
    padding: 0 $sassy-spacing-x-small;
}

.disablePadding {
    padding: 0;
}

.mobileItemGrid {
    position: relative;
    flex-basis: 50%;
    padding: $sassy-spacing-small $sassy-spacing-x-small;
}

.swiperItemFavorites {
    padding: 0 !important;
}

.hideTile {
    display: none;
}
