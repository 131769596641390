$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';

.paginationList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $sassy-spacing-x-large 0 $sassy-spacing-small;

    @include sassy-breakpoint-above(desktop) {
        margin: $sassy-spacing-x-large 0 $sassy-spacing-large;
    }
}

.paginationItem {
    @include sassy-font-sub-header($type: normal);
    margin-right: $sassy-spacing-x-small;

    @include sassy-breakpoint-above(desktop) {
        margin-right: $sassy-spacing-small;
    }

    &:last-of-type {
        margin: 0;
    }

    &.page {
        width: 48px;
    }

    &.arrowWrapper {
        position: relative;
    }
}
