$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_spacing';

.wrapper {
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}

.fullWidthWrapper {
    @include primary-viewport-width;
}

.innerWrapper {
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    @include sassy-breakpoint-below(tablet-portrait) {
        flex-wrap: nowrap;
    }
}

.fullWidthInnerWrapper {
    padding: 0 $sassy-spacing-small;
}

// .specify added to bump css specifity for avoiding overrides
// Override occurs when new css lazy loads (hover on notifications panel)
.link.specify {
    margin: $sassy-spacing-x-small $sassy-spacing-x-small 0 0;
    display: flex;
    white-space: nowrap;
    text-decoration: none;
    &:last-child {
        margin-right: 0;
    }
}
