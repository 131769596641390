$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';

$bottom_content_module_width: 1018px;

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
}

.bottomModule {
    max-width: $bottom_content_module_width;
    align-self: center;
    @include sassy-breakpoint-above(tablet-portrait) {
        margin: $sassy-spacing-x-large auto $sassy-spacing-large;
    }
}

.headerLeftWrapper {
    display: flex;
    flex-direction: column;
    margin: $sassy-spacing-small 0 0 0;
}
.headerLeft {
    display: flex;
    align-items: center;
}

.spacer {
    margin-top: $sassy-spacing-small;
}

.mainContent {
    display: flex;
    margin-top: $sassy-spacing-small;
}
