$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.container {
    position: relative; // position relative keeps input caret at the place when scrolling, iOS - safari/chrome bug fix.
    background: $sassy-color-white;

    @if $device-type == 'responsive' {
        margin-bottom: 0;
        padding-bottom: $sassy-spacing-small;
        border-bottom: 1px solid $sassy-color-dolphin;
    }
}
