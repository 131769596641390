$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

.author {
    display: flex;
    align-items: center;
    margin-bottom: $sassy-spacing-small;
}

.authorName {
    @include sassy-font-body($size: small);
}

.authorLogo {
    background: $sassy-color-white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: $sassy-spacing-small;
}

.expertLogo {
    @extend .authorLogo;
    background: $sassy-color-pitch-black;
    fill: $sassy-color-white;
    padding: $sassy-spacing-x-small;
}

.authorInfo {
    display: flex;
    flex-direction: column;
}
