@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import './variables';

@mixin layout-spacing() {
    padding-right: $sassy-spacing-small;
    padding-left: $sassy-spacing-small;
}

@mixin container-border() {
    border-bottom: $container-border;
}

@mixin tile-row($tilesPerRow) {
    flex-basis: calc(100% / #{$tilesPerRow});
    max-width: calc(100% / #{$tilesPerRow}); // IE11 flexbox/border-box issue
}

@mixin section-divider() {
    @include primary-viewport-width;
    border-bottom: $sassy-spacing-x-small solid $sassy-color-moonstone;
}
