$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.moreFiltersButton {
    @include sassy-font-body($size: none, $type: heavy);
    cursor: pointer;
}

.clearAllButton {
    @include sassy-font-body($size: small, $type: none);
    margin-bottom: $sassy-spacing-x-small;
}
