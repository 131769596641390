$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_breakpoints';

.wrapper {
    padding: $sassy-spacing-medium;
    border: 1px solid $sassy-color-dolphin;
    color: $sassy-color-noir;

    &:not(:last-child) {
        border-bottom: none;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        border-left: none;
        border-right: none;
        padding-left: 0;

        &:first-child {
            border-top: none;
        }
    }
}

.icon {
    width: 30px;
    height: 20px;
}

.questionWrapper {
    @include sassy-font-body($size: large);
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: medium);
    }
}

.questionWrapperExpanded {
    @include sassy-font-body($size: large, $type: heavy);

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: medium, $type: heavy);
    }
}

.question {
    max-width: 90%;
}

.answer {
    @include sassy-font-body;
    text-align: left;
    overflow: hidden;
    height: 0;
}

.answerExpanded {
    padding-top: $sassy-spacing-small;
    height: unset;
}

.inherit {
    all: inherit;
}
