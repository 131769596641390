$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.wrapper {
    text-align: center;
    width: 70%;
    margin: $sassy-spacing-medium auto $sassy-spacing-xx-large;
}

.links {
    display: flex;
    flex-wrap: wrap;
    // Negative margins are required for correct flexbox element gaps
    margin: (-$sassy-spacing-x-small) (-$sassy-spacing-x-small * 0.5);
    padding-top: $sassy-spacing-small;
    justify-content: center;
}

// .specify added to bump css specifity for avoiding overrides
// Override occurs when new css lazy loads (hover on notifications panel)
.link.specify {
    // Dividing margins in half because flexbox wrap won't collapse them
    margin: $sassy-spacing-x-small ($sassy-spacing-x-small * 0.5);
}
