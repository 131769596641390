$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.hiddenPlaceholder {
    display: none;
}

.selectWrapper {
    height: 100%;
    overflow: hidden;
    select::-ms-expand {
        display: none;
    }
}

.select {
    box-shadow: 0 0 0 30px $sassy-color-white inset !important;
    background: none;
    border: none;
    @include sassy-font-body;
    color: $sassy-color-noir;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    &:-ms-expand {
        display: none;
    }
    height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:disabled {
        cursor: not-allowed;
        box-shadow: 0 0 0 30px $sassy-color-dolphin inset !important;
    }
    transition: padding-top 0.1s ease;
    padding-right: $sassy-spacing-larger;
}

.withFakeNativePlaceholder {
    color: $sassy-color-satan;
}

.selectAnimatedPlaceholderPadding {
    padding-top: 15px;
}

.basicSelectArrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
}

.paddingLeftMedium {
    padding-left: $sassy-spacing-small;
}

.paddingLeftSmall {
    padding-left: $sassy-spacing-x-small;
}
