$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_buyerLinks';

:local {
    .arrowContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow {
        max-width: none !important;
        transition: transform 0.3s ease-in-out, fill 0.3s ease-in-out;
    }
    .sizeSmall {
        width: 14px;
    }
    .sizeMedium {
        width: 16px;
    }
    .sizeHeaderXLarge {
        width: 28px;
    }
    .default {
        fill: $sassy-color-satan;
    }
    .defaultHover {
        fill: $sassy-color-satan;
    }
    .whiteFill {
        fill: $sassy-color-white;
        &:hover {
            fill: $sassy-color-buyer-primary;
            cursor: pointer;
        }
    }
    .blackFill {
        fill: $sassy-color-pitch-black;
        &:hover {
            cursor: pointer;
        }
    }
    .linkPrimary {
        fill: $sassy-color-noir;
        &:hover {
            fill: $sassy-color-buyer-primary;
            cursor: pointer;
        }
        @include sassy-buyer-link-touch-color($sassy-color-noir);
    }
    .linkPrimaryHover {
        fill: $sassy-color-buyer-primary !important; // needs to be !important to override fill: currentColor from dibs vg
        @include sassy-buyer-link-touch-fill($sassy-color-noir);
        cursor: pointer;
    }
    .disabled {
        fill: $sassy-color-storm;
    }
    .disabled:hover {
        cursor: not-allowed;
    }

    .arrowDown {
        transform: rotate(180deg);
    }

    .paddingRight {
        padding-right: $sassy-spacing-small;
    }

    .paddingRightSmall {
        padding-right: $sassy-spacing-x-small;
    }
}
