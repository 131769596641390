$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';

.question {
    padding: $sassy-spacing-small 0;
    border-bottom: 1px solid $sassy-color-dolphin;
    overflow: hidden;
    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
}

.questionHeader {
    margin-bottom: $sassy-spacing-small;
}

.questionTitle {
    @include sassy-font-body($size: large, $type: heavy);
    &:not(:hover) {
        text-decoration: none;
    }
}

.answerCount {
    @include sassy-font-body;
}

.button {
    padding-left: $sassy-spacing-small;
}
