$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';

.description {
    @include sassy-font-body;
    padding-bottom: $sassy-spacing-medium;
    color: $sassy-color-noir;
}
