$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.arrowItem {
    @include sassy-font-sub-header($size: none, $type: normal);
    @include sassy-buyer-link-no-decoration();
    display: flex;
    align-items: center;
    color: $sassy-color-noir;
    padding: $sassy-spacing-x-small 0;

    :global(.main-content-mobile) & {
        @include sassy-breakpoint-below(mobile) {
            position: absolute;
            top: -68px;

            .isLeft {
                left: 0;
            }

            &:not(.isLeft) {
                right: 0;
            }
        }
    }
}

.arrow {
    width: 18px;
    height: 18px;
    margin: 0 0 0 $sassy-spacing-x-small;

    .isLeft & {
        margin: 0 $sassy-spacing-x-small 0 0;
    }
}
