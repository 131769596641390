$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$sizes: (
    'none': 0,
    'xSmall': $sassy-spacing-x-small,
);

.searchTagWrapper {
    display: flex;

    @each $name, $sassy-var in $sizes {
        &.#{$name}Spacing {
            margin-right: $sassy-var;
            margin-bottom: $sassy-var;
        }
    }
}
.searchTag {
    display: flex;
    max-width: 100%;
    align-items: center;
    white-space: nowrap;
    color: $sassy-color-iron;
    background: $sassy-color-white;
    border-radius: 16px;
}
.searchTagEnabled {
    color: $sassy-color-pitch-black;
    background: $sassy-color-alabaster;
}
.searchTagLabel {
    @include sassy-font-body($size: small, $type: none);
    padding: 4px 0 4px $sassy-spacing-x-small;
    min-width: 0;
}
// increase specificity in order to override Link component styles from NotificationQueue
button.deleteButton {
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
    // original design - padding: 0 $sassy-spacing-x-small;
    // increase clickable area by adding padding in all directions. remainder of
    // original padding should use margin, which does not increase clickable area.
    padding: #{$sassy-spacing-x-small * 0.5};
    margin: 0 #{$sassy-spacing-x-small * 0.5};
    svg {
        width: 12px;
        height: 12px;
        fill: $sassy-color-iron;
    }
}
// increase specificity in order to override Link component styles from NotificationQueue
button.deleteButtonEnabled {
    cursor: pointer;
    &:hover {
        .closeIcon {
            fill: $sassy-color-pitch-black;
        }
    }
}
.searchTagActive {
    @if $user-type == 'internal' {
        background: $sassy-color-internal-tertiary;
    } @else if $user-type == 'dealer' {
        background: $sassy-color-dealer-tertiary;
    } @else {
        background: $sassy-color-moonstone;
    }
}
.noDeleteButton {
    padding-right: $sassy-spacing-x-small;
}
