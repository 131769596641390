$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

$faq_container_width: 888px;

.wrapper {
    margin: 0 auto;
    margin-bottom: $sassy-spacing-xx-large;
    max-width: $faq_container_width;
    @include sassy-breakpoint-below(mobile) {
        @include primary-viewport-width;
        margin-top: $sassy-spacing-small;
        margin-bottom: 0;
        border-top: $sassy-spacing-x-small solid $sassy-color-moonstone;
        padding: $sassy-spacing-small;
    }
}

.header {
    @include sassy-font-header($size: medium);
    text-align: center;
    margin: $sassy-spacing-small 0;
    @include sassy-breakpoint-below(mobile) {
        margin: 0 0 $sassy-spacing-small;
    }
}

.viewMoreButtonWrapper {
    display: flex;
    justify-content: center;
}

.viewMoreButton {
    margin-top: $sassy-spacing-small;
    width: 200px;
    @include sassy-breakpoint-below(mobile) {
        width: 100%;
        margin-top: 0;
    }
}

.hideItem {
    display: none;
}
