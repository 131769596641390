$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_buyerLinks';

.item {
    @include sassy-buyer-link-no-decoration();
    display: block;
    width: 100%;
    padding: $sassy-spacing-x-small 0;
    border: 1px solid $sassy-color-storm;
    color: $sassy-color-noir;
    text-align: center;

    &.isSelected {
        background: $sassy-color-moonstone;
    }
}
