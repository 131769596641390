$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

.topSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: $sassy-spacing-small;
    margin-bottom: $sassy-spacing-small;
}

.middleSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: $sassy-spacing-small;
}

.wrapper {
    margin-top: $sassy-spacing-medium;
}

.appliedFiltersWrapper {
    flex: 1;
}

.sortAndPriceContainer {
    display: flex;
    align-items: center;
    @include sassy-font-body($size: small);
}
.label {
    margin: 0 $sassy-spacing-small 0 $sassy-spacing-large;
    @include sassy-font-body($type: heavy, $size: small);
}
