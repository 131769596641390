$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';

.container {
    display: flex;
    flex-wrap: wrap;
}

.tileWidth {
    flex-basis: 50%;
    max-width: 50%;

    &.desktop3ItemsRow {
        @include sassy-breakpoint-above(desktop) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3);
        }
    }

    &.desktop4ItemsRow {
        @include sassy-breakpoint-above(desktop) {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
}
