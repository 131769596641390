$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';

.noInventoryAvailable {
    padding: $sassy-spacing-large 0;
    @include sassy-breakpoint-below(tablet-portrait) {
        padding-top: 0;
    }
}
