$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_colors';
@import '../../../scss/layout/_mixins';

.container {
    @include primary-viewport-width;
}

.background {
    background: $sassy-color-moonstone;
}

.padding {
    @include layout-spacing;
}
