$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';

.wrapper {
    padding: $sassy-spacing-large 0 $sassy-spacing-small;

    @include sassy-breakpoint-above(tablet-portrait) {
        border: 1px solid $sassy-color-dolphin;
        padding: $sassy-spacing-large $sassy-spacing-x-large;
    }
}
