$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_webkitLineClamp';

.answer {
    @include sassy-font-body;
    align-self: start;
    background: $sassy-color-alabaster;
    padding: $sassy-spacing-small;
    margin-bottom: $sassy-spacing-medium;
    &:only-child,
    &:last-child {
        margin-bottom: 0;
    }
}
