$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';

.title {
    @include sassy-font-body($size: small, $type: heavy);
    margin: $sassy-spacing-small 0 0;
}

.description {
    @include sassy-font-body($size: small);

    // No classname for any html elements in description because it gets returned to us from cms service
    &:not(:last-child),
    p:not(:last-child) {
        margin: 0 0 $sassy-spacing-small;
    }

    ul {
        list-style: inside;
        padding-left: $sassy-spacing-small;
    }
}
